<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>商品信息列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="商品名"
              v-model="page.name"
              clearable
              @clear="onSubmit"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="商品编码"
              v-model="page.gtin"
              clearable
              @clear="onSubmit"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.goods_type"
              placeholder="商品类型"
              clearable
              @clear="typeClear"
            >
              <el-option value="0" label="全部"></el-option>
              <el-option value="1" label="国产食品"></el-option>
              <el-option value="2" label="进口食品"></el-option>
              <el-option value="3" label="农产品"></el-option>
              <el-option value="4" label="其他产品"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="middle"
              >查询</el-button
            >
            <el-button type="success" @click="onCollect" size="middle"
              >收藏商品</el-button
            >
            <el-button type="success" @click="handleShowAddGoods" size="middle"
              >新品赋码</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-upload
              action
              accept=".xlsx, .xls"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleImport"
            >
              <el-button type="success" size="middle">导入</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="商品名"
                v-model="page.name"
                clearable
                class="input-with-select"
                @clear="onSubmit(page.name)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="商品编码"
                v-model="page.gtin"
                clearable
                class="input-with-select"
                @clear="onSubmit(page.gtin)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.goods_type" placeholder="商品类型">
                <el-option value="0" label="全部"></el-option>
                <el-option value="1" label="国产食品"></el-option>
                <el-option value="2" label="进口食品"></el-option>
                <el-option value="3" label="农产品"></el-option>
                <el-option value="4" label="其他产品"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl mr10">
            <el-form-item size="medium">
              <div class="df">
                <el-button
                  type="primary"
                  @click="onSubmit"
                  class="general_bgc general_border cfff"
                  >查询</el-button
                >
                <el-button type="success" @click="onCollect">收藏商品</el-button>
                <el-button type="success" @click="handleShowAddGoods">新品赋码</el-button>
                <div class="ml20">
                  <el-upload
                          action
                          accept = ".xlsx, .xls"
                          :auto-upload="false"
                          :show-file-list="false"
                          :on-change="handleImport">
                    <el-button type="success">导入</el-button>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="gtin"
            min-width="120"
            :show-overflow-tooltip="true"
            label="商品编码"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="商品名"
            min-width="120"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="manufacturer"
            min-width="120"
            :show-overflow-tooltip="true"
            label="生产商"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="stock"
            min-width="120"
            :show-overflow-tooltip="true"
            label="库存(kg)"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="num"
            min-width="120"
            :show-overflow-tooltip="true"
            label="数量"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="type"
            min-width="120"
            :show-overflow-tooltip="true"
            label="类型"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handleBatch(scope.row)"
                >批次信息</el-button
              ><el-button
                type="text"
                class="disib"
                size="mini"
                @click="handleUnit(scope.row)"
                >单位配置</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>

      <el-image-viewer
        style="z-index: 9999"
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="url"
      />

      <el-dialog
        :title="'批次信息[' + goodsName + ']'"
        :visible.sync="dialogTableVisible"
        width="60%"
      >
        <div style="text-align: left; margin-bottom: 5px">
          <el-popover placement="top" width="500" v-model="createBatchVisible">
            <div class="p15">
              <el-form
                :model="batchForm"
                :rules="batchRules"
                ref="batchForm"
                label-width="100px"
                class="demo-dForm"
              >
                <el-form-item :label="'批号'" prop="batchNumber" required>
                  <div style="width: 80%">
                    <el-input v-model="batchForm.batchNumber"></el-input>
                  </div>
                </el-form-item>

                <el-form-item label="库存/kg" prop="stock" required>
                  <div style="width: 80%">
                    <el-input v-model="batchForm.stock"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="单价/元" prop="price" required>
                  <div style="width: 80%">
                    <el-input v-model="batchForm.price"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="类型" required>
                  <div class="fl">
                    <el-select v-model="selfProduced" placeholder="请选择类型">
                      <el-option label="外购" value="waigou"></el-option>
                      <el-option label="自产" value="zichan"></el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createBatchVisible = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="createBatch"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button
              slot="reference"
              size="small"
              type="success"
              @click="batch_number = ''"
              >创建批号</el-button
            >
          </el-popover>
        </div>
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="6" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="批次号"
                v-model="batchPage.batch_number"
                clearable
                @clear="batchSearch(batchPage.batch_number)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="batchSearch"
                class="general_bgc general_border cfff"
                >查询</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
        <el-table :data="gridData" border fit height="400">
          <el-table-column
            property="batchNumber"
            label="批号"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            property="stock"
            label="库存(kg)"
            min-width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            property="quantity"
            label="数量"
            min-width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            property="unitKgPrice"
            label="单价(元)"
            min-width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            property="selfProduced"
            label="类型"
            min-width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handleDetail(scope.row)"
                >详情</el-button
              >
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="handleShowDamage(scope.row)"
                >报损报溢</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="batchTotal"
            :current-page="batchCurrentPage"
            @current-change="batchCurrentChange"
          >
          </el-pagination>
        </div>
      </el-dialog>

      <el-dialog :title="'收藏商品'" :visible.sync="collectVisible" width="35%">
        <div class="tl" style="margin-bottom: 8px">
          <el-input
            size="small"
            v-model="goodsPage.keyword"
            style="width: 250px"
            placeholder="请输入商品编码或商品名称查询"
          ></el-input>
          <el-button style="margin-left: 8px" size="small" @click="searchGoods"
            >查询</el-button
          >
        </div>
        <el-table :data="goodsData" border fit height="400">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="进口/出口">
                  <span>{{ props.row.goodsCategory }}</span>
                </el-form-item>
                <el-form-item label="生产商">
                  <span>{{ props.row.manuName }}</span>
                </el-form-item>
                <el-form-item label="原产国">
                  <span>{{ props.row.originCountryName }}</span>
                </el-form-item>
                <el-form-item label="商品类型">
                  <span>{{ props.row.goodsType }}</span>
                </el-form-item>
                <el-form-item label="商品规格">
                  <span>{{ props.row.spec }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column
            property="goodsName"
            label="商品名称"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            property="code"
            label="商品编码"
            min-width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handleCollect(scope.row)"
                >收藏商品</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="goodsTotal"
            :current-page="goodsCurrentPage"
            @current-change="goodsCurrentChange"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <el-dialog :title="'报损报溢'" :visible.sync="showDamage" width="25%">
        <div v-if="showDamage">
          <el-form
            :model="dForm"
            :rules="dRules"
            ref="dForm"
            label-width="100px"
            class="demo-dForm"
          >
            <el-form-item label="不计入成本">
              <div class="fl">
                <el-checkbox
                  size="medium"
                  :border="true"
                  v-model="dForm.isCost"
                ></el-checkbox>
              </div>
            </el-form-item>
            <el-form-item label="类型" prop="type" required>
              <div class="fl">
                <el-select v-model="dForm.type" placeholder="请选择类型">
                  <el-option label="盘点" value="盘点"></el-option>
                  <el-option label="报损" value="报损"></el-option>
                  <el-option label="报溢" value="报溢"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="商品名" required>
              <div style="width: 80%">
                <el-input v-model="dForm.goodsName" :disabled="true"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="批号" required>
              <div style="width: 80%">
                <el-input
                  v-model="dForm.batchNumber"
                  :disabled="true"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item
              :label="'规格/' + unitList[unitIndex].unit_name"
              v-if="unitList && unitList.length > 0"
              prop="specs"
              required
            >
              <div class="df">
                <div style="width: 60%">
                  <el-input
                    placeholder="请填写数量"
                    v-model="dForm.specs"
                  ></el-input>
                </div>
                <div style="width: 20%">
                  <el-select v-model="unitIndex" placeholder="请选择单位">
                    <el-option
                      v-for="(item, index) in unitList"
                      :label="item.unit_name"
                      :value="index"
                      :key="index"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              v-if="unitList && unitList.length > 0"
              :label="(dForm.type == '盘点' ? '库存' : dForm.type) + '/kg'"
              required
            >
              <div style="width: 80%">
                <el-input :disabled="true" v-model="damageWeight"></el-input>
              </div>
            </el-form-item>
            <el-form-item
              v-else
              :label="(dForm.type == '盘点' ? '库存' : dForm.type) + '/kg'"
              prop="damageWeight1"
              required
            >
              <div style="width: 80%">
                <el-input v-model="dForm.damageWeight1"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="原因" prop="reason" required>
              <div class="fl">
                <el-select v-model="dForm.reason" placeholder="请选择原因">
                  <el-option label="正常消耗" value="0"></el-option>
                  <el-option label="储存损耗" value="1"></el-option>
                  <el-option label="自定义" value="2"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item
              label="自定义原因"
              prop="customReason"
              required
              v-if="dForm.reason == 2"
            >
              <div style="width: 80%">
                <el-input v-model="dForm.customReason"></el-input>
              </div>
            </el-form-item>
            <div class="tc">
              <el-button type="primary" @click="submitdForm('dForm')"
                >提交</el-button
              >
            </div>
          </el-form>
        </div>
      </el-dialog>
      <!--      单位配置-->
      <el-dialog
        :title="'单位配置[' + goodsName + ']'"
        :visible.sync="showUnitList"
        width="60%"
      >
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="6" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="批次号"
                v-model="batchPage.batch_number"
                clearable
                @clear="batchSearch(batchPage.batch_number)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="batchSearch"
                class="general_bgc general_border cfff mr20"
                >查询</el-button
              >
              <el-popover placement="top" width="600" v-model="showAddUnit">
                <div class="p15">
                  <el-form
                    :model="unitForm"
                    :rules="unitRules"
                    ref="unitForm"
                    label-width="120px"
                    class="demo-dForm"
                  >
                    <el-form-item :label="'单位名称'" prop="unitName" required>
                      <div style="width: 80%">
                        <el-input v-model="unitForm.unitName"></el-input>
                      </div>
                    </el-form-item>

                    <el-form-item
                      label="单位重量/kg"
                      prop="unitWeight"
                      required
                    >
                      <div style="width: 80%">
                        <el-input v-model="unitForm.unitWeight"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item
                      label="进价/元"
                      prop="purchasingPrice"
                      required
                    >
                      <div style="width: 80%">
                        <el-input v-model="unitForm.purchasingPrice"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="售价/元" prop="price" required>
                      <div style="width: 80%">
                        <el-input v-model="unitForm.price"></el-input>
                      </div>
                    </el-form-item>
                  </el-form>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="showAddUnit = false"
                      >取消</el-button
                    >
                    <el-button type="primary" size="mini" @click="createUnit"
                      >确定</el-button
                    >
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="success"
                  @click="handleShowUnit(0)"
                  >新增</el-button
                >
              </el-popover>
            </el-form-item>
          </el-col>
        </el-form>
        <el-table :data="unitGridData" border fit height="400">
          <el-table-column
            property="unitName"
            label="单位名称"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            property="unitWeight"
            label="单位重量/kg"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            property="purchasingPrice"
            label="进价"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            property="price"
            label="售价"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                slot="reference"
                type="text"
                class="disib orange mr20"
                size="mini"
                @click="handleShowUnit(1, scope.row)"
                >修改</el-button
              >
              <el-button
                type="text"
                class="disib red"
                size="mini"
                @click="deleteUnit(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="batchTotal"
            :current-page="batchCurrentPage"
            @current-change="batchCurrentChange"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <el-dialog title="修改单位配置" :visible.sync="showUpateUnit" width="25%">
        <div class="p15">
          <el-form
            :model="unitForm"
            :rules="unitRules"
            ref="unitForm"
            label-width="120px"
            class="demo-dForm"
          >
            <el-form-item :label="'单位名称'" prop="unitName" required>
              <div style="width: 80%">
                <el-input v-model="unitForm.unitName"></el-input>
              </div>
            </el-form-item>

            <el-form-item label="单位重量/kg" prop="unitWeight" required>
              <div style="width: 80%">
                <el-input v-model="unitForm.unitWeight"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="进价/元" prop="purchasingPrice" required>
              <div style="width: 80%">
                <el-input v-model="unitForm.purchasingPrice"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="售价/元" prop="price" required>
              <div style="width: 80%">
                <el-input v-model="unitForm.price"></el-input>
              </div>
            </el-form-item>
          </el-form>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="showUpateUnit = false"
              >取消</el-button
            >
            <el-button type="primary" size="mini" @click="updateUnit"
              >确定</el-button
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :visible.sync="showProgress"
        :title="progressTitle"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <el-progress
          :text-inside="true"
          status="success"
          :stroke-width="30"
          :percentage="percentage"
        ></el-progress>
        <div v-if="errorData.length > 0" class="mt50">
          <div class="tc">
            <h3>以下商品导入失败</h3>
          </div>
          <el-table :data="errorData" border fit>
            <el-table-column
              property="goodsName"
              label="商品名称"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="spec"
              label="规格"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="batchNumber"
              label="商品批号"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="goodsType"
              label="商品类型"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="category"
              label="商品分类"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="stock"
              label="库存"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="price"
              label="单价"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="manuName"
              label="生产商名称"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="originCountryName"
              label="原产国"
              min-width="150"
              align="center"
            ></el-table-column>
          </el-table>
          <div>共计{{ errorData.length }}条上传失败</div>
        </div>
        <div v-show="showProgressButton" class="tc mt50">
          <el-button type="primary" @click="showProgress = false"
            >确定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog :title="'新品赋码'" :visible.sync="showAddGoods" width="40%">
        <div>
          <el-form
            :model="goodsForm"
            :rules="gRules"
            ref="goodsForm"
            label-width="200px"
            class="demo-dForm"
          >
            <el-form-item label="商品名称" prop="goodsName" required>
              <div style="width: 80%">
                <el-input v-model="goodsForm.goodsName"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="规格" prop="spec" required>
              <div style="width: 80%">
                <el-input v-model="goodsForm.spec"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="商品分类" prop="category" required>
              <div class="fl">
                <el-select
                  v-model="goodsForm.category"
                  placeholder="请选择商品分类"
                >
                  <el-option label="进口" value="进口"></el-option>
                  <el-option label="国产" value="国产"></el-option>
                  <el-option label="农产品" value="农产品"></el-option>
                  <el-option label="其他产品" value="其他产品"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="商品类型" prop="goodsType" required>
              <div class="fl">
                <el-select
                  v-model="goodsForm.goodsType"
                  placeholder="请选择商品类型"
                >
                  <el-option label="冷链水产品" value="冷链水产品"></el-option>
                  <el-option label="冷链畜肉" value="冷链畜肉"></el-option>
                  <el-option label="冷链禽肉" value="冷链禽肉"></el-option>
                  <el-option label="果蔬" value="果蔬"></el-option>
                  <el-option label="甜品" value="甜品"></el-option>
                  <el-option label="兽药" value="兽药"></el-option>
                  <el-option label="农药" value="农药"></el-option>
                  <el-option label="包材" value="包材"></el-option>
                  <el-option label="肥料" value="肥料"></el-option>
                  <el-option label="饲料" value="饲料"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="批号">
              <div style="width: 80%">
                <el-input v-model="goodsForm.batchNumber"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="库存/kg">
              <div style="width: 80%">
                <el-input v-model="goodsForm.stock"></el-input>
              </div>
            </el-form-item>
            <el-form-item
              label="单价(填写库存是必填)"
              :required="goodsForm.stock"
            >
              <div style="width: 80%">
                <el-input v-model="goodsForm.price"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="生产商名称" prop="manuName" required>
              <div style="width: 80%">
                <el-input v-model="goodsForm.manuName"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="原产国(进口商品时必填)">
              <div style="width: 80%">
                <el-input v-model="goodsForm.originCountryName"></el-input>
              </div>
            </el-form-item>

            <div
              style="
                display: flex;
                justify-content: space-around;
                margin-top: 40px;
              "
            >
              <el-button type="warning" @click="showAddGoods = false"
                >取消</el-button
              >
              <el-button type="primary" @click="submitGoodsForm()"
                >提交</el-button
              >
            </div>
          </el-form>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import axios from "@/util/api";
import api from "@/util/extra-api";
import noBaseExtraAxios from "@/util/no-base-extra-api";
import { local, session } from "@/util/util";
import { mapState } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import * as XLSX from "xlsx/xlsx.mjs";
export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      showAddGoods: false,
      errorData: [],
      showProgressButton: false,
      showProgress: false,
      progressTitle: "上传中",
      percentage: 0,
      showAddUnit: false,
      showUpateUnit: false,
      unitForm: {
        gtin: null,
        unitName: "",
        unitWeight: null,
        price: null,
        purchasingPrice: null,
      },
      unitGridData: [],
      showUnitList: false,
      unitParams: {
        page: 0,
        limit: 10,
        gtin: "",
        companyId: "",
      },
      batchForm: {
        batchNumber: null,
        stock: null,
        price: null,
        selfProduced: "外购",
        goodsId: null,
        companyId: null,
      },
      batchRules: {
        batchNumber: [
          { required: true, message: "请填写批号", trigger: "blur" },
        ],
        stock: [{ required: true, message: "请填写库存", trigger: "blur" }],
        price: [{ required: true, message: "请填写价格", trigger: "blur" }],
      },
      dRules: {
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        specs: [{ required: true, message: "请填写规格", trigger: "blur" }],
        reason: [{ required: true, message: "请选择原因", trigger: "blur" }],
        customReason: [
          { required: true, message: "请填写自定义原因", trigger: "blur" },
        ],
        damageWeight1: [
          { required: true, message: "请填写数量", trigger: "blur" },
        ],
      },
      unitRules: {
        unitName: [
          { required: true, message: "请填写单位名称", trigger: "blur" },
        ],
        unitWeight: [
          { required: true, message: "请填写单位重量", trigger: "blur" },
        ],
        purchasingPrice: [
          { required: true, message: "请填写进价", trigger: "blur" },
        ],
        price: [{ required: true, message: "请填写售价", trigger: "blur" }],
      },
      gRules: {
        goodsName: [
          { required: true, message: "请填写商品名称", trigger: "blur" },
        ],
        spec: [{ required: true, message: "请填写规格", trigger: "blur" }],
        goodsType: [
          { required: true, message: "请选择商品类型", trigger: "blur" },
        ],
        category: [
          { required: true, message: "请选择商品分类", trigger: "blur" },
        ],
        manuName: [
          { required: true, message: "请填写生产商名称", trigger: "blur" },
        ],
        originCountryName: [
          { required: true, message: "请填写原产国", trigger: "blur" },
        ],
      },
      dForm: {},
      showDamage: false,
      user: "",
      page: {
        pager_offset: "0",
        pager_openset: "10",
        name: "",
        gtin: "",
        goods_type: "0",
      },
      batchPage: {
        pager_offset: "0",
        pager_openset: "10",
        goods_id: "",
        company_id: "",
        gtin: "",
        batch_number: "",
      },
      goodsPage: {
        limit: 10,
        page: 1,
        keyword: "",
      },
      goodsName: "",
      timer: "",
      loading: false,
      currentPage: 1,
      total: 0,
      batchCurrentPage: 1,
      batchTotal: 0,
      goodsCurrentPage: 1,
      goodsTotal: 0,
      tableData: [],
      gridData: [],
      goodsData: [],
      activeName: "1",
      company_id: "",
      url: "",
      batch_number: "",
      showViewer: false,
      dialogTableVisible: false,
      createBatchVisible: false,
      collectVisible: false,
      selfProduced: "外购",
      unitList: [],
      unitIndex: 0,
      goodsForm: {
        goodsName: "",
        spec: "",
        batchNumber: null,
        goodsType: null,
        category: "",
        stock: null,
        price: null,
        manuName: "",
        originCountryName: null,
      },
    };
  },
  filters: {},
  watch: {
    createBatchVisible(v) {
      if (!v) {
        this.batchForm.batchNumber = null;
        this.batchForm.stock = null;
        this.batchForm.price = null;
        this.selfProduced = "外购";
        this.batchForm.selfProduced = "外购";
      }
    },
    showProgress(v) {
      if (!v) {
        this.errorData = [];
        this.showProgressButton = false;
      }
    },
  },
  computed: {
    ...mapState({
      goodsState: (state) => state.goodsManage.goodsState,
    }),
    damageWeight() {
      return this.unitList && this.unitList.length > 0
        ? this.dForm.specs
          ? Number(this.dForm.specs) * this.unitList[this.unitIndex].unit_weight
          : null
        : null;
    },
  },
  mounted() {
    this.page = Object.assign({}, this.goodsState);
    this.page.company_id = local.get("company_id") + "";
    this.$set(this.page, "goods_type", "0");
    // this.page.goods_type = "0";
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
  },
  methods: {
    typeClear() {
      this.$set(this.page, "goods_type", "0");
      this.onSubmit()
    },
    // typeChange(value) {
    //   // console.log(value,123);
    //   this.page.goods_type = value;
    // },
    submitGoodsForm() {
      this.$refs["goodsForm"].validate((valid) => {
        if (valid) {
          api.post("/v1/pc/stock/newGtin", this.goodsForm).then((res) => {
            if (res.code === 200) {
              this.init(this.page);
            }
          });
        }
      });
    },
    handleShowAddGoods() {
      this.goodsForm = {
        companyId: this.page.company_id,
        goodsName: "",
        spec: "",
        batchNumber: null,
        goodsType: null,
        category: "",
        stock: null,
        price: null,
        manuName: "",
        originCountryName: null,
      };
      this.showAddGoods = true;
    },
    deleteUnit(row) {
      api
        .post("/v1/pc/unit/delete", {
          id: row.id,
          companyId: this.page.company_id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.showAddGoods = false;
            this.handleUnit(this.unitParams);
          }
        });
    },
    updateUnit() {
      this.$refs["unitForm"].validate((valid) => {
        if (valid) {
          api.post("/v1/pc/unit/update", this.unitForm).then((res) => {
            if (res.code === 200) {
              this.showUpateUnit = false;
              this.handleUnit(this.unitParams);
            }
          });
        }
      });
    },
    createUnit() {
      this.$refs["unitForm"].validate((valid) => {
        if (valid) {
          api.post("/v1/pc/unit/add", this.unitForm).then((res) => {
            if (res.code === 200) {
              this.showAddUnit = false;
              this.handleUnit(this.unitParams);
            }
          });
        }
      });
    },
    handleShowUnit(type, row) {
      if (type === 0) {
        this.unitForm = {
          companyId: this.page.company_id,
          gtin: this.unitParams.gtin,
          unitName: "",
          unitWeight: null,
          price: null,
          purchasingPrice: null,
        };
      } else {
        this.showUpateUnit = true;
        this.unitForm = {
          id: row.id,
          companyId: this.page.company_id,
          gtin: this.unitParams.gtin,
          unitName: row.unitName,
          unitWeight: row.unitWeight,
          price: row.price,
          purchasingPrice: row.purchasingPrice,
        };
      }
    },
    handleUnit(row) {
      this.unitParams.companyId = local.get("company_id");
      this.unitParams.gtin = row.gtin;
      api.get("/v1/pc/unit/list", this.unitParams).then((res) => {
        if (res.code === 200) {
          this.goodsName = row.name ? row.name : this.goodsName;
          this.unitGridData = res.data;
          this.showUnitList = true;
        }
      });
    },
    readFile(file) {
      //文件读取
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file); //以二进制的方式读取
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
    },
    async handleImport(ev) {
      let _this = this;
      let file = ev.raw;
      if (!file) {
        return;
      } else {
        _this.showProgress = true;
        _this.showProgressButton = false;
        let data = await this.readFile(file);
        let workbook = XLSX.read(data, { type: "binary" }); //解析二进制格式数据
        let worksheet = workbook.Sheets[workbook.SheetNames[0]]; //获取第一个Sheet
        for (let key in worksheet) {
          if (key === "A1") {
            worksheet[key].v = "goodsName";
            worksheet[key].w = "goodsName";
          }
          switch (key) {
            case "A1":
              worksheet[key].v = "goodsName";
              worksheet[key].w = "goodsName";
              break;
            case "B1":
              worksheet[key].v = "spec";
              worksheet[key].w = "spec";
              break;
            case "C1":
              worksheet[key].v = "batchNumber";
              worksheet[key].w = "batchNumber";
              break;
            case "D1":
              worksheet[key].v = "goodsType";
              worksheet[key].w = "goodsType";
              break;
            case "E1":
              worksheet[key].v = "category";
              worksheet[key].w = "category";
              break;
            case "F1":
              worksheet[key].v = "stock";
              worksheet[key].w = "stock";
              break;
            case "G1":
              worksheet[key].v = "price";
              worksheet[key].w = "price";
              break;
            case "H1":
              worksheet[key].v = "manuName";
              worksheet[key].w = "manuName";
              break;
            case "I1":
              worksheet[key].v = "originCountryName";
              worksheet[key].w = "originCountryName";
              break;
          }
        }
        let result = XLSX.utils.sheet_to_json(worksheet); //json数据格式
        let l = result.length;
        let sl = 0;
        for (let item of result) {
          item.companyId = _this.page.company_id;
          sl = await this.importData(item, sl, l);
          if (sl === l - this.errorData.length) {
            this.progressTitle = "上传完成";
            this.showProgressButton = true;
            this.init(this.page);
          }
        }
      }
    },
    importData(item, sl, l) {
      let _this = this;
      return new Promise((resolve) => {
        api.post1("/v1/pc/stock/newGtin", item).then((res) => {
          if (res.code === 200) {
            sl++;
            _this.percentage = ((sl / l) * 100).toFixed(1);
            resolve(sl);
          } else {
            sl++;
            _this.errorData.push(item);
            resolve(sl);
          }
        });
      });
    },
    handleShowDamage(item) {
      this.dForm = {
        id: item.id,
        type: "盘点",
        isCost: item.stock < 0,
        goodsName: this.goodsName,
        batchNumber: item.batchNumber,
        weight: null,
        reason: "",
        customReason: "",
        stock: item.stock,
        damageWeight1: null,
      };
      this.unitList = item.unitList;
      this.showDamage = true;
    },
    submitdForm() {
      this.dialogTableVisible = false;
      this.$refs["dForm"].validate((valid) => {
        if (valid) {
          let params = {};
          if (this.unitList && this.unitList.length > 0) {
            params = {
              companyId: this.page.company_id,
              id: this.dForm.id,
              isCost: this.dForm.isCost,
              remark:
                this.dForm.reason == "0" || "1"
                  ? this.dForm.reason
                  : this.dForm.customReason,
              newStock:
                this.dForm.type === "盘点"
                  ? this.damageWeight
                  : this.dForm.type === "报损"
                  ? Number(this.dForm.stock) - this.damageWeight
                  : Number(this.dForm.stock) + this.damageWeight,
            };
          } else {
            params = {
              companyId: this.page.company_id,
              id: this.dForm.id,
              isCost: this.dForm.isCost,
              remark:
                this.dForm.reason == "0" || "1"
                  ? this.dForm.reason
                  : this.dForm.customReason,
              newStock:
                this.dForm.type === "盘点"
                  ? this.dForm.damageWeight1
                  : this.dForm.type === "报损"
                  ? Number(this.dForm.stock) - this.dForm.damageWeight1
                  : Number(this.dForm.stock) + this.dForm.damageWeight1,
            };
          }
          api.post("/v1/pc/stock/damaged", params).then(async (res) => {
            if (res.code === 200) {
              this.dialogTableVisible = true;
              this.showDamage = false;
              this.unitList = [];
              let response = await this.initBatch(this.batchPage);
              this.gridData = response.data.batch_list.map((m) => {
                let quantity = "";
                if (m.units && m.units.length > 0)
                  m.units.forEach((item) => {
                    quantity += item.unit_num + item.unit_name;
                  });
                return {
                  ...m,
                  quantity: quantity,
                };
              });
              this.init(this.page);
              this.$forceUpdate();
            }
          });
        }
      });
    },
    init(params) {
      axios.get("/pc/goods/list", params).then((v) => {
        this.tableData = v.data.goods_list.map((m) => {
          let num = "";
          if (m.unit !== "未设置单位!" && m.unit.length > 0) {
            m.unit.forEach((item, index) => {
              if (index !== m.unit.length - 1) {
                num += item.unit_num + item.unit_name + "+";
              } else {
                num += item.unit_num + item.unit_name;
              }
            });
          } else {
            num = "无单位";
          }
          return {
            ...m,
            num,
          };
        });
        this.total = v.data.pager_count;
      });
    },
    initBatch(params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/stock/batch_stock", params)
          .then((v) => {
            resolve(v);
          })
          .catch((err) => {
            reject(v);
          });
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("goodsManage/setNewPage", this.page);
      this.init(this.page);
    },
    async batchSearch() {
      this.currentPage = 1;
      this.batchPage.pager_offset = "0";
      this.$store.dispatch("goodsManage/setNewPage", this.batchPage);
      let res = await this.initBatch(this.batchPage);
      this.gridData = res.data.batch_list;
      this.batchTotal = res.data.pager_count;
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("goodsManage/setNewPage", this.page);
      this.init(this.page);
    },
    goodsCurrentChange(page) {
      this.goodsCurrentPage = page;
      this.goodsPage.page = page;
      this.searchGoods(this.goodsPage);
    },
    async batchCurrentChange(page) {
      this.batchCurrentPage = page;
      this.batchPage.pager_offset = String((page - 1) * 10);
      let res = await this.initBatch(this.batchPage);
      this.gridData = res.data.batch_list;
      this.batchTotal = res.data.pager_count;
    },
    async handleBatch(row) {
      this.batchPage.batch_number = "";
      this.batchPage.goods_id = row.id + "";
      this.batchPage.gtin = row.gtin + "";
      this.batchPage.name = "";
      this.batchPage.company_id = local.get("company_id") + "";
      let res = await this.initBatch(this.batchPage);
      this.batchPage.name = row.name + "";
      this.gridData = res.data.batch_list.map((m) => {
        let quantity = "";
        if (m.units)
          m.units.forEach((item) => {
            quantity += item.unit_num + item.unit_name;
          });
        return {
          ...m,
          quantity: quantity,
        };
      });
      this.batchTotal = res.data.pager_count;
      this.goodsName = row.name ? row.name : "";
      this.dialogTableVisible = true;
    },
    handleDetail(row) {
      const { batchNumber } = row;
      this.$router.push({
        name: "batchDetail",
        query: {
          batch_number: batchNumber,
          name: this.batchPage.name,
          gtin: this.batchPage.gtin,
        },
      });
    },
    handleViewPic(src) {
      if (src) {
        let path = src.slice(1, src.length - 1).split(", ");
        this.url = path;
        this.showViewer = true;
      }
    },
    closeViewer() {
      this.showViewer = false;
    },
    createBatch() {
      const { goods_id, company_id } = this.batchPage;
      this.batchForm.goodsId = goods_id;
      this.batchForm.companyId = company_id;
      this.batchForm.selfProduced = this.selfProduced === "自产";
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          api
            .post("/v1/pc/stock/createBatchStock", this.batchForm)
            .then(async (v) => {
              this.batchCurrentPage = 1;
              this.batchPage.pager_offset = "0";
              let res = await this.initBatch(this.batchPage);
              this.gridData = res.data.batch_list;
              this.batchTotal = res.data.pager_count;
              this.createBatchVisible = false;
            });
        } else {
          return false;
        }
      });
    },
    onCollect() {
      this.gtinSearch = "";
      this.collectVisible = true;
    },
    searchGoods() {
      noBaseExtraAxios
        .get(
          "https://1234263519431432.cn-chengdu.fc.aliyuncs.com/2016-08-15/proxy/cll-gtin-api/getGtins/api/gtins",
          this.goodsPage
        )
        .then((response) => {
          const { data, total } = response;
          //console.log("data: ", data);
          this.goodsData = data;
          this.goodsTotal = total;
        });
    },
    handleCollect(row) {
      let gtin_info = JSON.stringify(row);
      let company_id = local.get("company_id") + "";
      axios
        .post("/pc/goods/collect", {
          gtin_info,
          company_id,
        })
        .then(async (v) => {
          this.$notify({
            title: "成功",
            message: "收藏成功",
            type: "success",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>

<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
